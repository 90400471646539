import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './contexts/AuthContext'

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isAuth, user }) => (
      <Route
        render={props =>
          isAuth ? <Component {...props} user={user} /> : <Redirect to='/login' />}
        {...rest}
      />
    )}
  </AuthConsumer>
)

export default ProtectedRoute
