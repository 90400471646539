import config from '../config'

const { apiURL } = config
const env = localStorage.getItem('finBlocksEnv')

const postFinblocks = (url, body = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  return fetch(`${apiURL}/${env}/finblocks${url}`, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(body)
  })
}

const post = (url, body = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  return fetch(apiURL + url, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(body)
  })
}

const put = (url, body = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  return fetch(apiURL + url, {
    method: 'PUT',
    headers,
    credentials: 'include',
    body: JSON.stringify(body)
  })
}

const get = (url) => {
  const headers = {
    Accept: 'application/json'
  }

  return fetch(apiURL + url, {
    method: 'GET',
    headers,
    credentials: 'include'
  })
}

const getFinblocks = (url) => {
  const headers = {
    Accept: 'application/json'
  }

  return fetch(`${apiURL}/${env}/finblocks${url}`, {
    method: 'GET',
    headers,
    credentials: 'include'
  })
}

export { post, get, put, postFinblocks, getFinblocks }
