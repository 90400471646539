import React from 'react'
import { post, put } from '../lib/fetch'

const AuthContext = React.createContext()

class AuthProvider extends React.Component {
  constructor () {
    super()
    this.state = {
      isAuth: localStorage.getItem('finBlocksExpirationDate') > Date.now(),
      user: JSON.parse(localStorage.getItem('finBlocksUser')),
      env: localStorage.getItem('finBlocksEnv')
    }
    this.register = this.register.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.thirdPartyLogin = this.thirdPartyLogin.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.setEnvironment = this.setEnvironment.bind(this)
  }

  register (email, company, password, marketing, history) {
    const response = post('/users', {
      email,
      company,
      password,
      marketing
    })
      .then(res => {
        return res.json()
      })
      .then((json) => {
        if (json.error) {
          return ({ error: 'The user has already registered. Please Login' })
        } else {
          return this.login(email, password, history)
        }
      })
      .catch(() => {
        return ({ error: 'Please type a valid email address' })
      })
    return response
  }

  login (email, password, history) {
    const response = post('/login', {
      email,
      password
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          return ({ error: 'Please type a valid email address' })
        }

        const { user, tokenMaxAge } = json
        const env = user.access.includes('live') ? 'live' : 'test'

        this.setState({
          isAuth: true,
          user,
          tokenMaxAge,
          env
        })
        localStorage.setItem('finBlocksExpirationDate', Date.now() + tokenMaxAge)
        localStorage.setItem('finBlocksUser', JSON.stringify(user))
        localStorage.setItem('finBlocksEnv', env)

        return history.push('/dashboard')
      })
      .catch(() => {
        return ({ error: 'Login failed. Username and password don\'t match.' })
      })
    return response
  }

  updateUser (details, history) {
    const { user } = this.state
    put('/users',
      { user, ...details })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ user: response })
        localStorage.setItem('finBlocksUser', JSON.stringify(response))
        return history.push('/dashboard')
      })
      .catch(error => {
        console.log(error)
      })
  }

  thirdPartyLogin (code, history, provider) {
    const response = post(`/oauth/${provider}`, { code })
      .then(response => response.json())
      .then((json) => {
        if (json.error) {
          return ({ error: 'There was an error, please try again later' })
        }
        const { user, tokenMaxAge } = json
        const env = user.access.includes('live') ? 'live' : 'test'
        this.setState({
          isAuth: true,
          user,
          tokenMaxAge,
          env
        })
        localStorage.setItem('finBlocksExpirationDate', Date.now() + tokenMaxAge)
        localStorage.setItem('finBlocksUser', JSON.stringify(user))
        localStorage.setItem('finBlocksEnv', env)

        return history.push('/dashboard')
      })
      .catch(() => {
        return ({ error: 'OOPS! that didn\'t go well. Please try another way of login.' })
      })
    return response
  }

  setEnvironment (e) {
    const { value } = e.target
    this.setState({ env: value },
      () => localStorage.setItem('finBlocksEnv', value))
    window.location.reload(false)
  }

  logout () {
    localStorage.setItem('finBlocksExpirationDate', Date.now())
    localStorage.setItem('finBlocksUser', null)
    localStorage.setItem('finBlocksEnv', null)
    this.setState({
      isAuth: false
    })
  }

  render () {
    const { isAuth, user, env } = this.state
    const { login, logout, register, thirdPartyLogin, updateUser, setEnvironment } = this

    return (
      <AuthContext.Provider
        value={{
          isAuth,
          register,
          login,
          logout,
          thirdPartyLogin,
          updateUser,
          setEnvironment,
          user,
          env
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
