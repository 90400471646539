import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable'
import './App.scss'
import { AuthProvider } from './contexts/AuthContext.js'
import ProtectedRoute from './ProtectedRoute'

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
})

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
})

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
})
const ForgotPassword = Loadable({
  loader: () => import('./views/Pages/ForgotPassword'),
  loading
})

const ResetPassword = Loadable({
  loader: () => import('./views/Pages/ResetPassword/ResetPassword'),
  loading: loading
})

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
})

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
})

class App extends Component {
  render () {
    return (
      <AuthProvider>
        <HashRouter>
          <Switch>
            <Route exact path='/login' name='Login Page' component={Login} />
            <Route exact path='/register' name='Registeration Page' component={Register} />
            <Route exact path='/forgot-password' name='Forgot Password Page' component={ForgotPassword} />
            <Route path='/reset/:token' name='Reset Password Page' component={ResetPassword} />
            <Route exact path='/404' name='Page 404' component={Page404} />
            <Route exact path='/500' name='Page 500' component={Page500} />
            <ProtectedRoute path='/' name='Home' component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </AuthProvider>
    )
  }
}

export default App
